import React from "react"
import Img from "gatsby-image"

import BlockContent from "../blockContent/blockContent"
import Wrapper from "../layout/wrapper/wrapper"

import styles from "./iconWithText.module.scss"

const IconWithText = ({ title, text, icon, background }) => (
  <Wrapper background={background}>
    <div className={styles.item}>
      {icon ? (
        <Img
          fluid={icon?.asset.fluid}
          className={styles.image}
          imgStyle={{
            objectFit: "contain",
          }}
        />
      ) : null}

      <div className={styles.title}>
        <BlockContent blocks={title} />
      </div>
      <BlockContent blocks={text} />
    </div>
  </Wrapper>
)

export default IconWithText
