import React, { useState, useEffect, useRef } from "react"
import Slider from "react-slick"
import cx from "classnames"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Wrapper from "../layout/wrapper/wrapper"
import * as Modal from "../modal/modal"

import InlineImage from "../blockContent/inlineImage/inlineImage"
import { getDefault, getFullWidth } from "./carousel.helpers"

import ResizeIcon from "../../../assets/svg/resize.svg"
import styles from "./carousel.module.scss"
import "./slickOverrides.scss"

// todo: handling 3 and less slides
// tests
// storybook
// Refactor

const Carousel = ({ images, ratio }) => {
  const [activeSlide, setActiveSlide] = useState(0)
  const [noSlider, setNoSlider] = useState(false)
  const sliderRef = useRef()

  const settings = getDefault(setActiveSlide)
  const settingsOneSlide = getFullWidth(styles, activeSlide)

  useEffect(() => {
    if (images?.length <= 3) {
      setNoSlider(true)
      sliderRef.current.slickGoTo(1)
    }
  }, [images])

  const ModalSlide = ({ img, i }) => (
    <div key={`${img}-${i}`} className={styles.carouselSlide}>
      <div className={styles.imgWrapper}>
        <InlineImage image={img._rawAsset} alt={img.alt} styles={styles.img} />
      </div>
    </div>
  )

  return (
    <Wrapper noPadding>
      <div className={cx(styles.carousel, noSlider ? "noSlider" : "", ratio === "mobile" ? styles.mobileRatio : "")}>
        <Modal.Wizard>
          <Modal.Content>
            <Slider {...settingsOneSlide}>
              {images?.map((img, i) => (
                <ModalSlide img={img} key={`${img}-${i}`} />
              ))}
            </Slider>
          </Modal.Content>
          <Slider {...settings} ref={sliderRef}>
            {images?.map((img, i) => (
              <div key={`${img}-${i}`} className={styles.carouselSlide}>
                <div className={styles.carouselSlide}>
                  <div className={styles.imgWrapper}>
                    {i === activeSlide ? (
                      <Modal.Trigger>
                        <span>
                          <ResizeIcon className={styles.trigger} />
                        </span>
                      </Modal.Trigger>
                    ) : null}
                    <InlineImage image={img._rawAsset} alt={img.alt} styles={styles.img} />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </Modal.Wizard>
      </div>
    </Wrapper>
  )
}

export default Carousel
