import React from "react"
import cx from "classnames"

import styles from "./carousel.module.scss"

const Arrow = ({ direction, onClick }) => (
  <button alt={`go to ${direction} slide`} className={cx(styles.arrow, styles[direction])} onClick={onClick}></button>
)

export const getDefault = changeAction => ({
  focusOnSelect: true,
  className: "center",
  centerMode: true,
  infinite: true,
  centerPadding: "0px",
  slidesToShow: 3,
  speed: 500,
  dots: true,
  beforeChange: (current, next) => changeAction(next),
  nextArrow: <Arrow direction="next" />,
  prevArrow: <Arrow direction="prev" />,
})

export const getFullWidth = (styles, activeSlide) => ({
  focusOnSelect: true,
  infinite: true,
  className: styles.singleSlide,
  centerPadding: "0px",
  slidesToShow: 1,
  speed: 500,
  dots: true,
  initialSlide: activeSlide,
  nextArrow: <Arrow direction="next" />,
  prevArrow: <Arrow direction="prev" />,
})
