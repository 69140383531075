import React from "react"
import BlockContent from "../blockContent/blockContent"
import Wrapper from "../layout/wrapper/wrapper"

import styles from "./simpleText.module.scss"

const SimpleText = ({ blocks }) => (
  <Wrapper>
    <div className={styles.text}>
      <BlockContent blocks={blocks} />
    </div>
  </Wrapper>
)

export default SimpleText
