import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Seo from "../components/seo/seo"
import HeroWithImage from "../components/hero/heroWithImage/heroWithImage"
import Carousel from "../components/carousel/carousel"
import Quote from "../components/quote/quote"
import Columns from "../components/columns/columns"
import Cta from "../components/cta/cta"
import IconWithText from "../components/iconWithText/iconWithText"
import FullWidthImage from "../components/fullWidthImage/fullWidthImage"
import SimpleText from "../components/simpleText/simpleText"
import PageBuilder from "../components/pageBuilder/pageBuilder"

const CustomerStory = ({ data, pageContext }) => {
  const { content: page, seo } = data.sanityCs.tabs
  const [otherStories, setOtherStories] = useState([])

  useEffect(() => {
    setOtherStories(
      page.customerstories.map(
        item =>
          (item = {
            ...item.tabs.content,
            url: `/customer-stories/${item.tabs.content.slug.current}`,
            linkLabel: "Read More",
            text: item.tabs.csListingPreview.text,
          })
      )
    )
  }, [page.customerstories])

  return (
    <Layout>
      <Seo isBlogPost={false} title={page.title} path={pageContext.path} {...seo} />
      {page.hero ? (
        <HeroWithImage blocks={page.hero[0].children} image={page.heroImage} logo={page.brandLogo} small />
      ) : null}
      {page.quote ? <Quote quote={page.quote} background="none" author={page.author} /> : null}
      {page.simpleColumns?.simpleColumns?.length ? (
        <Columns columns={page.simpleColumns.simpleColumns} alignment="center" background="blue" pink={true} />
      ) : null}
      {page.text ? <SimpleText blocks={page.text} /> : null}
      {page.image ? <FullWidthImage alt={page.image.alt} image={page.image._rawAsset} /> : null}
      {page.iconWithText ? <IconWithText {...page.iconWithText} /> : null}
      {page.quote2 ? <Quote quote={page.quote2} background="pink" author={page.author2} /> : null}
      {page.iconWithText2 ? <IconWithText {...page.iconWithText2} /> : null}
      {page.carousel?.images.length ? <Carousel images={page?.carousel?.images} ratio={page.carousel.ratio} /> : null}
      {page.iconWithText3 ? <IconWithText {...page.iconWithText3} /> : null}
      {page.pageBuilder ? <PageBuilder pageBuilder={page.pageBuilder} /> : null}
      {page.carousel2?.images.length ? (
        <Carousel images={page?.carousel2?.images} ratio={page.carousel2.ratio} />
      ) : null}
      {otherStories.length ? (
        <Columns columns={otherStories} alignment="center" sectionTitle="Other Customer Stories" />
      ) : null}
      {page.cta ? <Cta {...page.cta} background="blue" /> : null}
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    sanityCs(tabs: { content: { slug: { current: { eq: $slug } } } }) {
      tabs {
        seo {
          canonical
          description
          metaTitle
          noIndex
          openGraphImage {
            asset {
              url
            }
          }
          removeSitemap
        }
        content {
          title
          hero: _rawHero
          heroImage {
            alt
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          brandLogo: logo {
            asset {
              fluid(maxWidth: 200) {
                ...GatsbySanityImageFluid
              }
            }
          }
          quote
          author
          quote2
          author2
          simpleColumns {
            simpleColumns {
              text
              title
            }
          }
          text: _rawText
          image {
            alt
            _rawAsset(resolveReferences: { maxDepth: 10 })
          }
          carousel {
            ratio
            images {
              alt
              _rawAsset(resolveReferences: { maxDepth: 10 })
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          carousel2 {
            ratio
            images {
              alt
              _rawAsset(resolveReferences: { maxDepth: 10 })
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          iconWithText {
            ...iconWithTextFragment
          }
          iconWithText2 {
            ...iconWithTextFragment
          }
          iconWithText3 {
            ...iconWithTextFragment
          }
          customerstories {
            tabs {
              csListingPreview {
                text: description
              }
              content {
                slug {
                  current
                }
                icon: logo {
                  asset {
                    fluid {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
              }
            }
          }
          cta {
            ...ctaFragment
          }
          pageBuilder {
            ...PageBuilder
          }
        }
      }
    }
  }
`

export default CustomerStory
