import React from "react"
// import Wrapper from "../layout/wrapper/wrapper"

import InlineImage from "../blockContent/inlineImage/inlineImage" // todo

const FullWidthImage = ({ image, alt }) => (
  // <Wrapper>
  <div className="grid">
    <div className="small-12 large-10 large-offset-1">
      <InlineImage image={image} alt={alt} />
    </div>
  </div>
  // </Wrapper>
)

export default FullWidthImage
