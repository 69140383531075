import React from "react"
import Img from "gatsby-image"
import cx from "classnames"

import AnimatedText from "../animatedText/animatedText"

import styles from "./heroWithImage.module.scss"

// Used at customer stories

const HeroWithImage = ({ blocks, subtitle, image, logo, small }) => (
  <div className={styles.full}>
    <Img className={styles.backgroundImg} fluid={image?.asset.fluid} />
    <Img className={styles.logo} fluid={logo?.asset.fluid} />
    <div className={styles.wrapper}>
      <h1 className={cx(styles.title, small ? styles.small : "")}>
        <AnimatedText blocks={blocks} />
      </h1>
      {subtitle ? <p>{subtitle}</p> : null}
    </div>
  </div>
)

export default HeroWithImage
